<template>
  <div class="index-page">
    <CallToAction :marketplaceInfo="marketplaceInfo"/>
    <!--      <Marketplace :marketplaceInfo="marketplaceInfo" />-->
    <Products :investing-price="investingPrice" :products="products"/>
    <Finance :list="financeList"/>
    <Network/>
    <Tokens :roobee-token-cards="roobeeTokenCards" :roobee-token-websites="roobeeTokenWebsites"/>
    <Ecosystem :ecosystem-products="ecosystemProducts"/>
    <StartInvesting/>
    <Reviews :reviews="reviews"/>
    <News :newsEn="newsEn" :newsRu="newsEn"/>
  </div>
</template>

<script>
export default {
  name: 'Index',

  components: {
    CallToAction: () => import('@/containers/Index/CallToAction'),
    // Marketplace: () => import('@/containers/Index/Marketplace'),
    Products: () => import('@/containers/Index/Products'),
    Finance: () => import('@/containers/Index/Finance'),
    Network: () => import('@/containers/Index/Network'),
    Tokens: () => import('@/containers/Index/Tokens'),
    Ecosystem: () => import('@/containers/Index/Ecosystem'),
    StartInvesting: () => import('@/containers/Index/StartInvesting'),
    Reviews: () => import('@/containers/Index/Reviews'),
    News: () => import('@/containers/Index/News')
  },

  data() {
    return {
      investingPrice: '10',

      marketplaceInfo: [
        {
          title: 'assets', // slug for i18n
          content: '1', // gif or img
          list: [
            'features', // slugs for i18n
            'buy-build'
          ]
        },
        {
          title: 'payment', // slug for i18n
          content: '2', // gif or img
          list: [
            'cards', // slugs for i18n
            'crypto'
          ]
        },
        {
          title: 'blockchain', // slug for i18n
          content: '3', // gif or img
          list: [
            'digital', // slugs for i18n
            'own-d-asset'
          ]
        }
      ],

      products: [
        {
          // icons must be in folder /public/images/products
          // icons must be called as slugs and be in png format
          slug: 'airbnb', // used for title (i18n), icon
          company: 'Airbnb Pre-IPO',
          props: [
            {
              title: 'price', // used with i18n
              value: '$ 206.74'
            },
            {
              title: 'd-down',
              value: '+40.83%'
            }
          ]
        },
        {
          slug: 'russel',
          company: 'iShares Russel 2000',
          props: [
            {
              title: 'price',
              value: '$ 233.59'
            },
            {
              title: 'd-down',
              value: '+19.14%'
            }
          ]
        },
        {
          slug: 'robinhood',
          company: 'Robinhood Pre-IPO',
          props: [
            {
              title: 'price',
              value: '$ 22'
            },
            {
              title: 'd-down',
              value: 'TBD'
            }
          ]
        },
        {
          slug: 'gold',
          company: 'SPDR Gold Trust',
          props: [
            {
              title: 'price',
              value: '$ 161.49'
            },
            {
              title: 'd-down',
              value: '-9.46%'
            }
          ]
        },
        {
          slug: 'cart',
          company: {
            en: 'Available to purchase in our app!',
            ru: 'Доступно для покупки на нашей платформе!'
          },
          props: [
            {
              title: 'price',
              value: {
                en: 'From $10',
                ru: 'От $10'
              }
            },
            {
              title: 'd-down',
              value: ''
            }
          ]
        }
      ],

      financeList: [
        'defi', // i18n slug
        'bsc',
        'one-place'
      ],

      roobeeTokenWebsites: [
        {
          title: 'tce', // i18n slug
          url: 'https://etherscan.io/token/0xa31b1767e09f842ecfd4bc471fe44f830e3891aa'
        },
        {
          title: 'tv',
          url: 'https://www.tradingview.com/symbols/ROOBEEBTC/?exchange=HITBTC'
        },
        {
          title: 'tcbsc',
          url: 'https://bscscan.com/token/0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe'
        }
      ],

      roobeeTokenCards: [
        {
          title: 'Gate.io',
          slug: 'gate-io', // for icon
          url: 'https://www.gate.io/trade/ROOBEE_USDT'
        },
        {
          title: 'KuCoin',
          slug: 'ku-coin',
          isSvg: true,
          url: 'https://www.kucoin.com/ru/trade/ROOBEE-BTC'
        },
        {
          title: 'BitForex',
          slug: 'bitforex',
          url: 'https://www.bitforex.com/en/spot/roobee_btc'
        },
        {
          title: 'HitBTC',
          slug: 'hitbtc',
          url: 'https://hitbtc.com/exchange/ROOBEE-to-BTC'
        },
        {
          title: 'EXMO',
          slug: 'exmo',
          url: 'https://exmo.me/en/trade/ROOBEE_BTC'
        },
        {
          title: 'ApeSwap',
          slug: 'ape-swap',
          isSvg: true,
          url: 'https://apeswap.finance/swap'
        },
      ],

      ecosystemProducts: [
        {
          slug: 'binance', // used for icon
          title: 'Binance',
          hashtag: 'blockchain'
        },
        {
          slug: 'bitgo',
          title: 'BitGo',
          hashtag: 'fintech'
        },
        {
          slug: 'sum-substance',
          title: 'Sum & Substance',
          hashtag: 'audit'
        },
        {
          slug: 'mercuryo',
          title: 'Mercuryo',
          hashtag: 'payments'
        },
        {
          slug: 'ambisafe',
          title: 'Ambisafe',
          hashtag: 'fintech'
        },
        {
          slug: 'changelly',
          title: 'Changelly',
          hashtag: 'payments'
        },
        {
          slug: 'trust-wallet',
          title: 'Trust Wallet',
          hashtag: 'storage'
        },
        {
          slug: 'trezor',
          title: 'TREZOR',
          hashtag: 'storage'
        },
        {
          slug: 'orderbook',
          title: 'Orderbook',
          hashtag: 'fintech'
        },
        {
          slug: 'gnosis',
          title: 'Gnosis',
          hashtag: 'blockchain',
          isSvg: true
        },
        {
          slug: 'unicorn-tc',
          title: 'Unicorn Tokenization Corp.',
          hashtag: 'fintech'
        },
        {
          slug: 'pancake',
          title: 'PancakeSwap',
          hashtag: 'fintech',
          isSvg: true
        }
      ],

      reviews: [
        {
          avatar: 'https://pbs.twimg.com/profile_images/1259845543779581957/0a_glnhg_400x400.jpg',
          author: 'dExplain',
          short_link: 'https://twitter.com/dExplain_com',
          link: 'https://twitter.com/dExplain_com/status/1329824348966883328',
          text: {
            en: 'Always got Alts with $ROOBEE :)',
            ru: 'Всегда получаю Альты с $ROOBEE :)'
          }
        },
        {
          avatar: 'https://pbs.twimg.com/profile_images/1252592317594308608/WEmeqaQs_400x400.jpg',
          author: 'Daniel',
          short_link: 'https://twitter.com/li_daniell',
          link: 'https://twitter.com/li_daniell/status/1370054275183869956',
          text: {
            en: 'I really believe in the project! I hope that everything will work out for the team. I look at their work and understand what the future is for Roobee!',
            ru: 'Я очень верю в проект! Надеюсь, что у команды все получится. Я смотрю на их работы и понимаю, какое будущее ждет Roobee!'
          }
        },
        {
          avatar: 'https://pbs.twimg.com/profile_images/1348589393473384450/Mspz_xBy_400x400.jpg',
          author: 'SwapSpace',
          short_link: 'https://twitter.com/SwapSpaceCo',
          link: 'https://twitter.com/SwapSpaceCo/status/1337410629691453441',
          text: {
            en: "@Roobee_invest provides the #blockchain-based platform that allows you to easily create your portfolio with various assets from stocks to #crypto. We're glad to say that $ROOBEE token is available for fast and secure exchanges on SwapSpace",
            ru: '@Roobee_invest предоставляет платформу на основе #блокчейн, которая позволяет вам легко создавать свой портфель с различными активами, от акций до #крипто. Мы рады сообщить, что токен $ROOBEE доступен для быстрого и безопасного обмена на SwapSpace.'
          }
        },
        {
          avatar: 'https://pbs.twimg.com/profile_images/1178341808457142274/dsVV-pDO_400x400.png',
          author: 'techno.ede',
          short_link: 'https://twitter.com/djf3d3x',
          link: 'https://twitter.com/djf3d3x/status/1222136428743790592',
          text: {
            en: 'roobee, good project and hackathon win.. must be great',
            ru: 'roobee, хороший проект и выиграли хакатон.. отлично'
          }
        }
      ],

      newsEn: [
  {
    avatar: '/news/publishers/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-06-25T00:00:00Z',
    text: 'LayerZero ($ZRO) is now on Roobee.finance!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/news/publishers/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-06-13T00:00:00Z',
    text: 'Unique Roobee X Scroll NFT!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/news/publishers/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-06-04T00:00:00Z',
    text: 'Updated Roobee Ecosystem!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/news/publishers/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-05-07T00:00:00Z',
    text: 'Universal Investment Strategy',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/news/publishers/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-04-25T00:00:00Z',
    text: 'Blast is now available on Roobee.finance!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/news/publishers/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-03-05T00:00:00Z',
    text: 'We’re starting to add support for RWA assets on Roobee.finance!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {
    avatar: '/news/publishers/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-02-28T00:00:00Z',
    text: 'Roobee Community on Lens!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
  {

    avatar: '/news/publishers/roobee-medium.svg',
    publisher: 'Roobee',
    date: '2024-02-12T00:00:00Z',
    text: 'Launching the Loyalty Program on Galxe!',
    link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78',
  },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2024-01-14T00:00:00Z',
          text: 'Join the KuCoin AMA With ROOBEE, 1,941,700 ROOBEE to Give Away!',
          link: 'https://medium.com/roobee-invest/join-the-kucoin-ama-with-roobee-1-941-700-roobee-to-give-away-49d121451f78'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-12-11T00:00:00Z',
          text: 'Join the #AMA with Roobee and Gate! $1000 giveaway!',
          link: 'https://medium.com/roobee-invest/join-the-ama-with-roobee-and-gate-1000-giveaway-b6d7f3337be8'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-12-07T00:00:00Z',
          text: 'We have an update',
          link: 'https://medium.com/roobee-invest/we-have-an-update-2c5b81ad5c61'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-11-14T00:00:00Z',
          text: 'Exchange ROOBEE in your Binance WEB3 wallet!',
          link: 'https://medium.com/roobee-invest/exchange-roobee-in-your-binance-web3-wallet-c364d40fa6f6'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-10-26T00:00:00Z',
          text: 'Scroll — New Vote on Roobee DAO!',
          link: 'https://medium.com/roobee-invest/scroll-new-vote-on-roobee-dao-79d42909d9ed'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-09-19T00:00:00Z',
          text: 'The ROOBEE token — where and how to use it?',
          link: 'https://medium.com/@roobee_invest/the-roobee-token-where-and-how-to-use-it-b43c472100dd'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-09-12T00:00:00Z',
          text: 'We’ve added support for Gnosis Chain to Roobee.finance!',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-gnosis-chain-to-roobee-finance-2789f76101c0'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-09-08T00:00:00Z',
          text: 'We’ve added support for Linea to Roobee.finance!',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-linea-to-roobee-finance-58ec750623e'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-08-31T00:00:00Z',
          text: 'opBNB — New Vote on Roobee DAO!',
          link: 'https://medium.com/roobee-invest/opbnb-new-vote-on-roobee-dao-81b2ce86906e'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-08-17T00:00:00Z',
          text: 'New Update on Roobee.finance — Introducing the Marketplace!',
          link: 'https://medium.com/roobee-invest/new-update-on-roobee-finance-introducing-the-marketplace-7f09d0940601'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-07-25T00:00:00Z',
          text: 'Roobee Community on DeBank!',
          link: 'https://medium.com/roobee-invest/roobee-community-on-debank-cf4aa07dc105'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-07-18T00:00:00Z',
          text: 'Upcoming Updates for Roobee.finance!',
          link: 'https://medium.com/roobee-invest/upcoming-updates-for-roobee-finance-cbcb4162b58c'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-07-06T00:00:00Z',
          text: 'We’ve added support for zkSync Era to Roobee.finance!',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-zksync-era-to-roobee-finance-bd57283132b4'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-05-23T00:00:00Z',
          text: 'We’ve got an update!',
          link: 'https://medium.com/roobee-invest/weve-got-an-update-9718cf36be54'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-04-27T00:00:00Z',
          text: 'Roobee is now on Zealy!',
          link: 'https://medium.com/roobee-invest/roobee-is-now-on-zealy-3397a7da8f8c'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-04-20T00:00:00Z',
          text: 'The testing for the Roobee DCA module is now live!',
          link: 'https://medium.com/roobee-invest/the-testing-for-the-roobee-dca-module-is-now-live-425aa187d3e2'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-04-03T00:00:00Z',
          text: 'What is Roobee: the platform’s DEcentralized part',
          link: 'https://medium.com/roobee-invest/what-is-roobee-the-platforms-decentralized-part-451d77ccdea7'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-03-13T00:00:00Z',
          text: 'What is Roobee: the platform’s centralized part',
          link: 'https://roobee-rus.medium.com/r-4dd3fc348f6b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-02-23T00:00:00Z',
          text: 'The testing for the Roobee DCA module goes live soon!',
          link: 'https://medium.com/roobee-invest/the-testing-for-the-roobee-dca-module-goes-live-soon-88b06e9c43c6'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-02-03T00:00:00Z',
          text: 'The DCA module testing whitelist is now open!',
          link: 'https://medium.com/roobee-invest/the-dca-module-testing-whitelist-is-now-open-d6c820592af'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-01-31T00:00:00Z',
          text: 'Roobee’s official Discord community is now live!',
          link: 'https://medium.com/roobee-invest/roobees-official-discord-community-is-now-live-e7667059f37b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-01-27T00:00:00Z',
          text: 'We’ve added support for Bitgert to Roobee.finance!',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-bitgert-to-roobee-finance-65fb8be47220'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-01-05T00:00:00Z',
          text: 'Roobee’s 2022 in Review',
          link: 'https://medium.com/@roobee_invest/roobees-2022-in-review-14ff418bf46a'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-12-29T00:00:00Z',
          text: 'AVAX is already available on Roobee.finance!',
          link: 'https://medium.com/roobee-invest/avax-is-already-available-on-roobee-finance-5f17c89b6717'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-10-18T00:00:00Z',
          text: 'Stake ROOBEE and farm BANANA!',
          link: 'https://medium.com/roobee-invest/stake-roobee-and-farm-banana-ddd74c239cdf'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-09-29T00:00:00Z',
          text: 'Roobee.finance has been added to DappRadar!',
          link: 'https://medium.com/roobee-invest/roobee-finance-has-been-added-to-dappradar-5dabe4d8cc08'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-09-26T00:00:00Z',
          text: 'We’ve added support for MathWallet to Roobee.finance!',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-mathwallet-to-roobee-finance-dad6efb202e3'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-07-26T00:00:00Z',
          text: 'We’ve added support for ONTO Wallet to Roobee.finance',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-onto-wallet-to-roobee-finance-9eeafcc7bf8c',
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-07-07T00:00:00Z',
          text: 'Roobee has partnered with O3 Labs',
          link: 'https://medium.com/roobee-invest/roobee-has-partnered-with-o3-labs-d23270676e6f',
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-07-05T00:00:00Z',
          text: 'KuCoin has added ROOBEE into its DeFi section',
          link: 'https://medium.com/roobee-invest/kucoin-has-added-roobee-into-its-defi-section-e893b68498d8',
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-06-14T00:00:00Z',
          text: 'A partnership BitKeep',
          link: 'https://medium.com/roobee-invest/a-partnership-bitkeep-5e3f0882777b',
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-04-21T00:00:00Z',
          text: 'Making the ROOBEE token compatible with the Polygon, Avalanche, Optimism, and Arbitrum networks.',
          link: 'https://medium.com/@roobee_invest/a-new-voting-round-on-roobee-dao-9abe2cc5f64b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-04-12T00:00:00Z',
          text: 'Key functionalities of Roobee.finance',
          link: 'https://medium.com/roobee-invest/key-functionalities-of-roobee-finance-84a96d6552eb'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-03-18T00:00:00Z',
          text: 'Adding support for the Ethereum, Polygon, Avalanche, Optimism, and Arbitrum networks to the ‘Invest’ section of Roobee.finance.',
          link: 'https://medium.com/roobee-invest/a-new-voting-round-on-roobee-dao-30eafea99f3a'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-03-05T00:00:00Z',
          text: 'New tokens in the ‘Invest’ section of Roobee.finance!',
          link: 'https://medium.com/roobee-invest/new-tokens-in-the-invest-section-of-roobee-finance-f0dec02e0bbd'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-02-23T00:00:00Z',
          text: 'Doge tokens on Roobee.finance: cast your vote on Roobee DAO!',
          link: 'https://medium.com/roobee-invest/doge-tokens-on-roobee-finance-cast-your-vote-on-roobee-dao-d604ed97c281'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-02-14T00:00:00Z',
          text: 'We’ve added support for Avalanche (C-Chain) to Roobee.finance!',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-avalanche-c-chain-to-roobee-finance-7b20375bd1d3'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-02-01T00:00:00Z',
          text: 'Uniswap V3 is now integrated into Roobee.Finance',
          link: 'https://medium.com/roobee-invest/uniswap-v3-is-now-integrated-into-roobee-finance-a91d8aa44617'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-01-27T00:00:00Z',
          text: 'We’ve added support for Polygon to Roobee.finance!',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-polygon-to-roobee-finance-7858bd1e164f'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-01-27T00:00:00Z',
          text: 'We’ve added support for Arbitrum to Roobee.finance!',
          link: 'https://medium.com/roobee-invest/weve-added-support-for-arbitrum-to-roobee-finance-dbe322a5071b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-11-09T00:00:00Z',
          text: 'Klarna’s pre-IPO is now available on the Roobee platform!',
          link: 'https://medium.com/roobee-invest/klarnas-pre-ipo-is-now-available-on-the-roobee-platform-5950fe556f83'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-10-22T00:00:00Z',
          text: 'Roobee announces a partnership with Polars!',
          link: 'https://medium.com/roobee-invest/roobee-announces-a-partnership-with-polars-f4331031bac6'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-10-22T00:00:00Z',
          text: 'A new Roobee.finance update!',
          link: 'https://medium.com/@roobee_invest/a-new-roobee-finance-update-322dff731499'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-31T00:00:00Z',
          text: 'Roobee.finance has received an update!',
          link: 'https://medium.com/roobee-invest/%EF%B8%8F-roobee-finance-has-received-an-update-c11d295753dc'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-24T00:00:00Z',
          text: 'ROOBEE/USDT trading pair launch on Bitforex! 1,000,000 $ROOBEE to be airdropped!',
          link: 'https://medium.com/roobee-invest/roobee-usdt-trading-pair-launch-on-bitforex-1-000-000-roobee-to-be-airdropped-95a4605b252a'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-17T00:00:00Z',
          text: 'Information on Airbnb (UAIR)!',
          link: 'https://medium.com/roobee-invest/information-on-airbnb-uair-77bb39bb6596'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-12T00:00:00Z',
          text: 'Public Roobee DAO launch!',
          link: 'https://medium.com/roobee-invest/public-roobee-dao-launch-a48e2f26e8ae'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-02T00:00:00Z',
          text: 'A fresh Roobee.finance update',
          link: 'https://medium.com/roobee-invest/%EF%B8%8F-a-fresh-roobee-finance-update-e09ce9389cbe'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-07-27T00:00:00Z',
          text: 'ROOBEE staking on gate.io! 35% APR!',
          link: 'https://medium.com/roobee-invest/roobee-staking-on-gate-io-35-apr-dd6064ac8251'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-04-23T00:00:00Z',
          text: 'SPAC/IPO portfolio is now available for purchase!',
          link: 'https://medium.com/roobee-invest/spac-ipo-portfolio-is-now-available-for-purchase-5239fa2bea4d'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-04-22T00:00:00Z',
          text: 'Roobee.finance is live!',
          link: 'https://medium.com/roobee-invest/roobee-finance-is-live-a9ab82726958'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-04-20T00:00:00Z',
          text: 'A major Roobee update!',
          link: 'https://medium.com/roobee-invest/a-major-roobee-update-339f32021228'
        },

        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-25T00:00:00Z',
          text: 'The Trending Tech portfolio is now available on Roobee!',
          link: 'https://medium.com/roobee-invest/the-trending-tech-portfolio-is-now-available-on-roobee-645e7e9672fb'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-24T00:00:00Z',
          text: 'A major Roobee update!',
          link: 'https://medium.com/roobee-invest/a-major-roobee-update-da59dd7501dc'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-12T00:00:00Z',
          text: 'World premiere: InVision’s pre-IPO is now available on the Roobee platform!',
          link: 'https://medium.com/roobee-invest/world-premiere-invisions-pre-ipo-is-now-available-on-the-roobee-platform-a5242366b408'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-11T00:00:00Z',
          text: 'Roobee X Gnosis Safe',
          link: 'https://medium.com/roobee-invest/roobee-x-gnosis-safe-66d7113b93f2'
        },

        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-01T00:00:00Z',
          text: 'Winners of Roobee Liquidity Carnival',
          link: 'https://medium.com/roobee-invest/winners-of-roobee-liquidity-carnival-75bfb5c2dede'
        },

        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-02-04T00:00:00Z',
          text: 'Why Roobee.finance and DeFi?',
          link: 'https://medium.com/roobee-invest/why-roobee-finance-and-defi-dc75f3cecfd7'
        },
      ],

      newsRu: [
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-08-17T00:00:00Z',
          text: 'Новое обновление Roobee.finance — встречайте Маркетплейс!',
          link: 'https://roobee-rus.medium.com/%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-roobee-finance-%D0%B2%D1%81%D1%82%D1%80%D0%B5%D1%87%D0%B0%D0%B9%D1%82%D0%B5-%D0%BC%D0%B0%D1%80%D0%BA%D0%B5%D1%82%D0%BF%D0%BB%D0%B5%D0%B9%D1%81-ab9a6e468cf5'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-07-25T00:00:00Z',
          text: 'Сообщество Roobee на DeBank!',
          link: 'https://roobee-rus.medium.com/%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE-roobee-%D0%BD%D0%B0-debank-d0b34a41f67d'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-07-18T00:00:00Z',
          text: 'Предстоящие обновления Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%BE%D1%8F%D1%89%D0%B8%D0%B5-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-roobee-finance-1e3d38b2fcd'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-07-06 T00:00:00Z',
          text: 'Добавлена поддержка сети zkSync Era на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B0-%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B0-%D1%81%D0%B5%D1%82%D0%B8-zksync-era-%D0%BD%D0%B0-roobee-finance-41d26289fd9b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-05-23T00:00:00Z',
          text: 'Мы с обновлением: новый сайт Roobee.finance',
          link: 'https://roobee-rus.medium.com/мы-с-обновлением-bfcfb31163c7'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-04-27T00:00:00Z',
          text: 'Roobee на Zealy!',
          link: 'https://roobee-rus.medium.com/roobee-%D0%BD%D0%B0-zealy-bfddfeb6bb97'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-04-20T00:00:00Z',
          text: 'Открылось тестирование DCA-модуля Roobee!',
          link: 'https://roobee-rus.medium.com/%D0%BE%D1%82%D0%BA%D1%80%D1%8B%D0%BB%D0%BE%D1%81%D1%8C-%D1%82%D0%B5%D1%81%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-dca-%D0%BC%D0%BE%D0%B4%D1%83%D0%BB%D1%8F-roobee-b04ed86e5bb0?postPublishedType=initial'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-04-03T00:00:00Z',
          text: 'Что такое Roobee: ДЕцентрализованная часть!',
          link: 'https://roobee-rus.medium.com/q-72d12929ffbf'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-03-13T00:00:00Z',
          text: 'Что такое Roobee: централизованная часть!',
          link: 'https://roobee-rus.medium.com/r-4dd3fc348f6b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-02-23T00:00:00Z',
          text: 'Заголовок: Готовьтесь к тестированию DCA-модуля Roobee!',
          link: 'https://medium.com/@roobee-rus/a-33c54f00983f'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-02-03T00:00:00Z',
          text: 'Открыли запись в вайтлист для тестирования DCA-модуля Roobee!',
          link: 'https://medium.com/@roobee-rus/dca-10736d03953d'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-01-27T00:00:00Z',
          text: 'Добавлена поддержка сети Bitgert на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B0-%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B0-%D1%81%D0%B5%D1%82%D0%B8-bitgert-%D0%BD%D0%B0-roobee-finance-a25bce7e488b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2023-01-05T00:00:00Z',
          text: 'Roobee: итоги 2022 года!',
          link: 'https://medium.com/@roobee-rus/roobee-%D0%B8%D1%82%D0%BE%D0%B3%D0%B8-2022-%D0%B3%D0%BE%D0%B4%D0%B0-d9b94581ba81'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-12-29T00:00:00Z',
          text: 'Заголовок: AVAX уже на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/avax-%D1%83%D0%B6%D0%B5-%D0%BD%D0%B0-roobee-finance-4477ee61397c'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-10-18T00:00:00Z',
          text: 'Стейкайте ROOBEE, фармите BANANA!',
          link: 'https://roobee-rus.medium.com/%D1%81%D1%82%D0%B5%D0%B9%D0%BA%D0%B0%D0%B9%D1%82%D0%B5-roobee-%D1%84%D0%B0%D1%80%D0%BC%D0%B8%D1%82%D0%B5-banana-cd9e2e298d22'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-09-29T00:00:00Z',
          text: 'RoobeeFinance был добавлен на DappRadar!',
          link: 'https://roobee-rus.medium.com/%D1%81%D1%82%D0%B5%D0%B9%D0%BA%D0%B0%D0%B9%D1%82%D0%B5-roobee-%D1%84%D0%B0%D1%80%D0%BC%D0%B8%D1%82%D0%B5-banana-cd9e2e298d22'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-09-26T00:00:00Z',
          text: 'Добавили поддержку кошелька MathWallet на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%B8%D0%BB%D0%B8-%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D1%83-%D0%BA%D0%BE%D1%88%D0%B5%D0%BB%D1%8C%D0%BA%D0%B0-mathwallet-%D0%BD%D0%B0-roobee-finance-6737e6f7c4a2'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-07-26T00:00:00Z',
          text: 'Добавили поддержку кошелька ONTO на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%B8%D0%BB%D0%B8-%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D1%83-%D0%BA%D0%BE%D1%88%D0%B5%D0%BB%D1%8C%D0%BA%D0%B0-onto-%D0%BD%D0%B0-roobee-finance-fbd1868980c4'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-07-07T00:00:00Z',
          text: 'Roobee заключает партнерство с O3 Labs',
          link: 'https://roobee-rus.medium.com/roobee-%D0%B7%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B0%D0%B5%D1%82-%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%BE-%D1%81-o3-labs-7eaf95358c94'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-07-05T00:00:00Z',
          text: 'Биржа KuCoin добавила $ROOBEE в секцию DeFi',
          link: 'https://roobee-rus.medium.com/%D0%B1%D0%B8%D1%80%D0%B6%D0%B0-kucoin-%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0-roobee-%D0%B2-%D1%81%D0%B5%D0%BA%D1%86%D0%B8%D1%8E-defi-ac1413ee9e78'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-06-14T00:00:00Z',
          text: 'Партнерство с BitKeep',
          link: 'https://roobee-rus.medium.com/%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%BE-%D1%81-bitkeep-70f429cbb9e?postPublishedType=initial'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-04-21T00:00:00Z',
          text: 'Выпуск токенов ROOBEE в сетях Polygon, Avalanche, Optimism и Arbitrum',
          link: 'https://roobee-rus.medium.com/%D0%B2%D1%8B%D0%BF%D1%83%D1%81%D0%BA-%D1%82%D0%BE%D0%BA%D0%B5%D0%BD%D0%BE%D0%B2-roobee-%D0%B2-%D1%81%D0%B5%D1%82%D1%8F%D1%85-polygon-avalanche-optimism-%D0%B8-arbitrum-4c6b8fbf5b5c'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-04-12T00:00:00Z',
          text: 'Основные Функции Roobee.finance',
          link: 'https://roobee-rus.medium.com/%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D0%B5-%D1%84%D1%83%D0%BD%D0%BA%D1%86%D0%B8%D0%B8-roobee-finance-d512fb149fa2'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-03-18T00:00:00Z',
          text: 'Интеграция раздела «invest» в сетях Ethereum, Polygon, Avalanche, Optimism и Arbitrum.',
          link: 'https://roobee-rus.medium.com/%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B3%D0%BE%D0%BB%D0%BE%D1%81%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BD%D0%B0-roobee-dao-fc4e5d167593'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-03-05T00:00:00Z',
          text: 'Новые токены в разделе Invest на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%BD%D0%BE%D0%B2%D1%8B%D0%B5-%D1%82%D0%BE%D0%BA%D0%B5%D0%BD%D1%8B-%D0%B2-%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D0%B5-invest-%D0%BD%D0%B0-roobee-finance-c2a41efed022'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-02-23T00:00:00Z',
          text: 'Doge-токены на Roobee.finance. Голосование на Roobee DAO!',
          link: 'https://roobee-rus.medium.com/doge-%D1%82%D0%BE%D0%BA%D0%B5%D0%BD%D1%8B-%D0%BD%D0%B0-roobee-finance-%D0%B3%D0%BE%D0%BB%D0%BE%D1%81%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BD%D0%B0-roobee-dao-22929aac2a89'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-02-14T00:00:00Z',
          text: 'Добавлена поддержка сети Avalanche (C-Chain) на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B0-%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B0-%D1%81%D0%B5%D1%82%D0%B8-avalanche-c-chain-%D0%BD%D0%B0-roobee-finance-5418b61c8b13'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-02-01T00:00:00Z',
          text: 'Интеграция Uniswap v3 на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%B8%D0%BD%D1%82%D0%B5%D0%B3%D1%80%D0%B0%D1%86%D0%B8%D1%8F-uniswap-v3-%D0%BD%D0%B0-roobee-finance-f80cdeb0d9d2'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-01-27T00:00:00Z',
          text: 'Добавлена поддержка сети Polygon на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B0-%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B0-%D1%81%D0%B5%D1%82%D0%B8-polygon-%D0%BD%D0%B0-roobee-finance-2e23affd1ab1'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2022-02-27T00:00:00Z',
          text: 'Добавлена поддержка сети Arbitrum на Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B0-%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B0-%D1%81%D0%B5%D1%82%D0%B8-arbitrum-%D0%BD%D0%B0-roobee-finance-83ea78ddf47b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-11-09T00:00:00Z',
          text: 'Pre-IPO Klarna уже на платформе Roobee!',
          link: 'https://roobee-rus.medium.com/pre-ipo-klarna-%D1%83%D0%B6%D0%B5-%D0%BD%D0%B0-%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B5-roobee-739a2462b218'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-10-22T00:00:00Z',
          text: 'Roobee объявляет о новом партнерстве с Polars!',
          link: 'https://roobee-rus.medium.com/roobee-%D0%BE%D0%B1%D1%8A%D1%8F%D0%B2%D0%BB%D1%8F%D0%B5%D1%82-%D0%BE-%D0%BD%D0%BE%D0%B2%D0%BE%D0%BC-%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%B5-%D1%81-polars-91b1f1b21f00'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-10-20T00:00:00Z',
          text: 'Горячее обновление Roobee.finance!',
          link: 'https://roobee-rus.medium.com/новое-обновление-roobee-finance-533d1f6dd595'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-31T00:00:00Z',
          text: 'Обновление roobee.finance',
          link: 'https://roobee-rus.medium.com/%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-roobee-finance-a39e4037da71'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-24T00:00:00Z',
          text: 'Открытие пары ROOBEE/USDT на Bitforex. 1 000 000 $ROOBEE к раздаче!',
          link: 'https://roobee-rus.medium.com/%D0%BE%D1%82%D0%BA%D1%80%D1%8B%D1%82%D0%B8%D0%B5-%D0%BF%D0%B0%D1%80%D1%8B-roobee-usdt-%D0%BD%D0%B0-bitforex-1-000-000-roobee-%D0%BA-%D1%80%D0%B0%D0%B7%D0%B4%D0%B0%D1%87%D0%B5-7642be01121b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-17T00:00:00Z',
          text: 'Информация по Airbnb (UAIR)!',
          link: 'https://roobee-rus.medium.com/%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE-airbnb-uair-2faeda979326'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-12T00:00:00Z',
          text: 'Публичный релиз Roobee DAO!',
          link: 'https://roobee-rus.medium.com/%D0%BF%D1%83%D0%B1%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%BB%D0%B8%D0%B7-roobee-dao-d30a96f3bfca'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-08-02T00:00:00Z',
          text: 'Обновление Roobee.finance',
          link: 'https://roobee-rus.medium.com/%EF%B8%8F%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-roobee-finance-b8c5560ec0d5'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-07-27T00:00:00Z',
          text: 'Стейкинг $ROOBEE на gate.io! 35% APR!',
          link: 'https://roobee-rus.medium.com/%D1%81%D1%82%D0%B5%D0%B9%D0%BA%D0%B8%D0%BD%D0%B3-roobee-%D0%BD%D0%B0-gate-io-35-apr-4d87f243dbb4'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-04-22T00:00:00Z',
          text: 'Портфель SPAC/IPO доступен на Roobee!',
          link: 'https://roobee-rus.medium.com/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%B5%D0%BB%D1%8C-spac-ipo-%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B5%D0%BD-%D0%BD%D0%B0-roobee-29a8f73584b'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-04-22T00:00:00Z',
          text: 'Состоялся релиз Roobee.finance!',
          link: 'https://roobee-rus.medium.com/%D1%81%D0%BE%D1%81%D1%82%D0%BE%D1%8F%D0%BB%D1%81%D1%8F-%D1%80%D0%B5%D0%BB%D0%B8%D0%B7-roobee-finance-29d83f8a6ad4'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-04-20T00:00:00Z',
          text: 'Большое обновление Roobee!',
          link: 'https://roobee-rus.medium.com/%D0%B1%D0%BE%D0%BB%D1%8C%D1%88%D0%BE%D0%B5-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-roobee-be712a303052'
        },

        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-25T00:00:00Z',
          text: 'Портфель Trending Tech доступен на Roobee!',
          link: 'https://roobee-rus.medium.com/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%B5%D0%BB%D1%8C-trending-tech-%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B5%D0%BD-%D0%BD%D0%B0-roobee-cbd221bea594'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-24T00:00:00Z',
          text: 'Крупное обновление Roobee!',
          link: 'https://roobee-rus.medium.com/%D0%BA%D1%80%D1%83%D0%BF%D0%BD%D0%BE%D0%B5-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-roobee-881e5d470c4'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-12T00:00:00Z',
          text: 'Мировая премьера: Pre-IPO inVision доступно на платформе Roobee!',
          link: 'https://roobee-rus.medium.com/%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D1%8F-%D0%BF%D1%80%D0%B5%D0%BC%D1%8C%D0%B5%D1%80%D0%B0-pre-ipo-invision-%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%BD%D0%BE-%D0%BD%D0%B0-%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B5-roobee-de188a7b7254?postPublishedType=initial'
        },
        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2021-03-17T00:00:00Z',
          text: 'Roobee X Gnosis Safe',
          link: 'https://roobee-rus.medium.com/roobee-x-gnosis-safe-7267baa4e5c4'
        },

        {
          avatar: '/news/publishers/roobee-medium.svg',
          publisher: 'Roobee',
          date: '2020-10-29T00:00:00Z',
          text: 'Старт DeFi площадки Roobee',
          link: 'https://medium.com/@roobee.rus/%D1%81%D1%82%D0%B0%D1%80%D1%82-defi-%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D0%BA%D0%B8-roobee-cf33135e6317'
        },
        {
          avatar: '/news/publishers/newsbit.png',
          publisher: 'Newsbit',
          date: '2020-10-27T00:00:00Z',
          text: 'Newsbit: Что такое Roobee?',
          link: 'https://newsbit.nl/wat-is-roobee/'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/index';
</style>
